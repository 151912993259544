// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_ssm_param_rmi_company_name": "/special-tools/feature/rmi-service-company",
    "aws_ssm_param_rmi_secret_name": "/special-tools/feature/rmi-service-password",
    "aws_ssm_param_rmi_user_name": "/special-tools/feature/rmi-service-username",
    "aws_ssm_param_rmi_type_id": "/special-tools/feature/rmi-service-type-id",
    "RumUserMonitoring": {
        "RumMonitorId": "cfe4e48c-76d6-463a-af01-a69bed2ef8ce",
        "RumRegion": "eu-central-1",
        "RumTelemetries": "errors,http"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:4acfd6d8-061a-4847-baef-f3adb03440fd",
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": [],
        "passwordPolicyMinLength": 8
    },
    "oauth": {
        "redirectSignOut": "http://localhost:3000/,https://feature-special-tools.d1dalb5kmxgzdl.amplifyapp.com/,https://feature-special-tools.rmi.sandbox.imagesimilarity.tecalliance.net/,https://rmi.sandbox.imagesimilarity.tecalliance.net/",
        "domain": "tecrmi-auth-feature-special-tools.auth.eu-central-1.amazoncognito.com",
        "responseType": "code",
        "redirectSignIn": "http://localhost:3000/,https://feature-special-tools.d1dalb5kmxgzdl.amplifyapp.com/,https://feature-special-tools.rmi.sandbox.imagesimilarity.tecalliance.net/,https://rmi.sandbox.imagesimilarity.tecalliance.net/",
        "scope": [
            "openid",
            "profile",
            "email"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_user_pools_id": "eu-central-1_hZ7rdv1B0",
    "aws_user_pools_web_client_id": "f6lrbaska173cs52j6chbs67n",
    "aws_cognito_region": "eu-central-1",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_custom_provider": "TecRMIfeaturespeoAzureADF50AEFB9",
    "aws_cognito_social_providers": [],
    "aws_user_files_s3_bucket": "tecrmi-feature-special-tools-frontend",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_appsync_apiKey": "da2-iziirsi5pjhtre7z5zhv6brf6u",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_graphqlEndpoint": "https://tgga3emnmravvb3vtwp2xqp7ea.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "backend": {
        "region": "eu-north-1",
        "bucket_name": "tecrmi-manuals-feature"
    },
    "amplify_appid": "d1dalb5kmxgzdl",
    "aws_project_region": "eu-central-1"
};

export default awsmobile;
